import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let kejianrouter = new Router({
    routes: [
        {
            path: '/',
            name: 'ceshihome',
            component: () => import('./views/ceshihome.vue'),
            hidden: true,
            redirect: 'Home',
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: () => import('./views/Home.vue'),
                    children: []
                }, /*{
                    path: '/mindfulness',
                    name: 'mindfulness',
                    component: () => import('./views/mindfulness/exercises.vue'),
                    children: []
                },*/{//第二页  青年
                    path: '/mindfulness/exercises',
                    name: 'mindfulness-exercises',
                    component: () => import('./views/mindfulness/exercises.vue'),
                    children: []
                }, {//第五页
                    path: '/mindfulness/youth',
                    name: 'mindfulness-youth',
                    component: () => import('./views/mindfulness/youth.vue'),
                    children: []
                },{//第六页 正念杂志
                    path: '/mindfulness/journal',
                    name: 'mindfulness-journal',
                    component: () => import('./views/mindfulness/journal.vue'),
                    children: []
                },{//第六页  关于我们
                    path: '/about/about-us',
                    name: 'about-us',
                    component: () => import('./views/about/about-us.vue'),
                    children: []
                },{//思维步骤
                    path: '/mind-steps',
                    name: 'mind-steps',
                    component: () => import('./views/mind-steps/index.vue'),
                    children: []
                },{//播客集
                    path: '/podcast-episodes',
                    name: 'podcast-episodes',
                    component: () => import('./views/podcast-episodes/index.vue'),
                    children: []
                },{//become a partner  成为合作伙伴
                    path: '/cooperation',
                    name: 'cooperation',
                    component: () => import('./views/cooperation/index.vue'),
                    children: []
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login.vue')
        },
    ]
})

// 判断是否需要登录权限 以及是否登录
kejianrouter.beforeEach((to, from, next) => {
    // 判断是否需要登录权限
    if (to.matched.some(res => res.meta.requireAuth)) {
        // 判断是否登录
        if (sessionStorage.getItem('token')) {
            next()
        } else {
            // 没登录则跳转到登录界面
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        next()
    }
})

export default kejianrouter
