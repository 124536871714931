<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: "App"
}
</script>

<style lang="scss">
//@import 'animate.css';
// 项目公共样式
@import "@/assets/styles/public.scss";

* {
  padding: 0;
  margin: 0;
  font-family: PingFangSC, PingFang SC;
}

html,
body {
  height: 100%;
}

#app {
  font-family: Arial Rounded MT Bold;
  font-weight: 400;
  font-size: 19px;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//公共
.flex {
  display: flex;
}

.war-pe {
  flex-wrap: wrap;
}

.ju-sa {
  justify-content: space-around;
}

.ju-sp {
  justify-content: space-between;
}

.ju-cn {
  justify-content: center;
}

.ju-en {
  justify-content: end;
}

.al-cn {
  align-items: center;
}

.fl-co {
  flex-direction: column;
}

.al-top {
  align-items: flex-start; //上对齐
}
</style>
